<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="text-primary pb-2">
        Professional Information
        <CIcon
          v-if="!isModalView"
          name="cil-info"
          class="hinticon-multi-select mr-1"
          v-c-tooltip="' See Profile Section below for Additional Details'"
        />
        <em
          v-if="!isModalView"
          class="fas fa-angle-double-down"
          style="cursor: pointer"
          @click="$parent.goToExpendableSection()"
        ></em>
      </h6>
      <div class="d-flex justify-content-end action-wrapper">
        <a class="px-2 btn" v-if="isEditable" @click="editProfessionalInfo">
          <i class="fas fa-pencil-alt" alt="Edit" />
        </a>
      </div>
    </div>
    <CRow>
      <CCol>
        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">Main Role/Expertise</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ speciality }}</span>
          </CCol>
        </CRow>
        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">Other Areas of Expertise</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ otherExpertise }}</span>
          </CCol>
        </CRow>
        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">Highest Qualification</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ qualificationName || "--" }}</span>
          </CCol>
        </CRow>
        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">Country Obtained</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ qualification_country }}</span>
          </CCol>
        </CRow>
        <CRow class="profileView" v-if="showMore">
          <CCol lg="4">
            <label class="text-gray">Year Obtained</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ qualification_year }}</span>
          </CCol>
        </CRow>
        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray"> Working As</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ candidateLevel || "--" }}</span>
          </CCol>
        </CRow>
        <CRow class="profileView no-line" v-if="showMore">
          <CCol lg="4">
            <label class="text-gray">Current Job Title</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ profile.current_job || "--" }}</span>
          </CCol>
        </CRow>
        <CRow>
          <CCol
            class="more-less bottom-line text-center"
            @click="ShowMoreLess()"
          >
            <i
              class="fa fa-chevron-circle-up"
              title="Show less"
              v-if="showMore"
              aria-hidden="true"
            ></i>
            <i
              class="fa fa-chevron-circle-down"
              title="Show more"
              v-else
              aria-hidden="true"
            ></i>
          </CCol>
        </CRow>
      </CCol>
    </CRow>

    <ProfessionalInfoNonClinicalEdit
      :candidateInfo="profile"
      :isShowPopup="EditModal.isShowPopup"
      @modalCallBack="ProfessionalInfoModalCallBack"
      v-if="EditModal.isShowPopup"
    />
    <Modal
      v-if="splConfirmModal.isShowPopup"
      :modalTitle="splConfirmModal.modalTitle"
      :modalColor="splConfirmModal.modalColor"
      :modalContent="splConfirmModal.modalContent"
      :isShowPopup="splConfirmModal.isShowPopup"
      :buttons="splConfirmModal.buttons"
      :modalCallBack="splModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import { Role, getScope } from "@/helpers/helper";
import ProfessionalInfoNonClinicalEdit from "./EditForm/ProfessionalInfoNonClinicalEdit";
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/reusable/Modal";
import isJobSupplier from "@/mixins/isJobSupplier";

export default {
  mixins: [isJobSupplier],
  props: {
    profile: {
      type: Object,
      default: null,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isModalView: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ProfessionalInfoNonClinicalEdit,
    Modal,
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      EditModal: {
        isShowPopup: false,
      },
      splConfirmModal: {
        modalColor: "primary",
        modalTitle: "NOTIFICATION",
        modalContent: "Do you need to reset the Special Interest?",
        isShowPopup: false,
        buttons: ["No", "Yes"],
      },
      showMore: false,
    };
  },
  computed: {
    ...mapGetters([
      "getLicense",
      "currentUserType",
      "isCandidateRoleFromAccessToken",
    ]),
    getCandidateSpecialInterest() {
      return (
        (this.profile?.candidate_specialities &&
          this.profile?.candidate_specialities) ||
        []
      );
    },
    speciality() {
      return this.profile.speciality?.speciality || "--";
    },
    candidateLevel() {
      return this.profile?.candidate_level?.candidate_level || "--";
    },
    qualification_year() {
      let index = this.profile.qualifications.findIndex(
        (x) => x.qualification_name === this.profile.highest_qualification_name
      );
      return this.profile.qualifications[index]?.year_awarded || "--";
    },
    qualification_country() {
      let index = this.profile.qualifications.findIndex(
        (x) => x.qualification_name === this.profile.highest_qualification_name
      );
      return this.profile.qualifications[index]?.country_name || "--";
    },
    highestQualification() {
      return (
        (this.profile?.qualifications &&
          this.profile.qualifications.length > 1 &&
          this.profile?.qualifications.filter(({ main }) => main)) ||
        (this.profile.qualifications.length === 1 &&
          this.profile?.qualifications) ||
        []
      );
    },
    qualificationName() {
      if (
        this.profile?.qualifications &&
        this.profile?.highest_qualification_name &&
        this.profile?.highest_qualification_name == "Other - not listed"
      ) {
        return _(this.profile?.qualifications)
          .filter((qualification) => {
            return (
              qualification?.main &&
              qualification?.qualification_name ==
                this.profile?.highest_qualification_name
            );
          })
          .map((qualification) => {
            return qualification?.other_qualification_name
              ? "Other - " + qualification?.other_qualification_name
              : null;
          })
          .value()
          .toString();
      } else {
        return (
          this.highestQualification.length &&
          this.highestQualification
            .map(
              ({
                qualification_name,
                description,
                qualification: { allow_subject_input },
              }) =>
                `${qualification_name}${
                  allow_subject_input && description
                    ? "(" + description + ")"
                    : ""
                }`
            )
            .join()
        );
      }
    },
    otherExpertise() {
      return (
        this.profile?.candidate_specialities
          .filter(({ main }) => !main)
          .map((v) => v?.speciality?.speciality)
          .join(", ") || "--"
      );
    },
  },
  methods: {
    ...mapActions([
      "updateCandidate",
      "fetchQualification",
      "deleteSplInterestWithoutToast",
      "fetchspecialInterest",
      "showToast",
      "fetchPQRLevels",
    ]),
    editProfessionalInfo() {
      this.EditModal.isShowPopup = true;
    },
    ProfessionalInfoModalCallBack(action, data, isSpecialityChanged) {
      this.EditModal.isShowPopup = false;
      if (action === true && data != null) {
        const { customer_uid } = this.profile;
        let appentAction = this.updateCandidate({ data, customer_uid });
        Promise.all([appentAction]).then((res) => {
          this.fetchPQRLevels();
          if (isSpecialityChanged?.length) {
            this.fetchQualification();
            if (
              this.getCandidateSpecialInterest?.length &&
              isSpecialityChanged.some((val) => val == "speciality_id")
            ) {
              this.splConfirmModal.isShowPopup = true;
            }
          }
        });
      }
    },
    splModalCallBack(action) {
      this.splConfirmModal.isShowPopup = false;
      if (action == "Yes") {
        let appendAction = [];
        this.getCandidateSpecialInterest.forEach((val) => {
          if (val.special_interest_id)
            appendAction = this.deleteSplInterestWithoutToast(
              val.candidate_speciality_id
            );
        });
        Promise.all([appendAction]).then((res) => {
          this.fetchspecialInterest();
          this.showToast({
            class: "bg-success text-white",
            message: "Special Interest reset successful",
          });
        });
        this.$parent.goToSplInterestSection();
      }
    },
    ShowMoreLess() {
      this.showMore = !this.showMore;
    },
  },
};
</script>
